// src/components/LandingPage.js
import React, { useEffect, useState } from 'react';
import './landing-page.scss'; // Import the styles
import Logo from '../../assets/logo.svg';
import LogoPNG from '../../assets/SOL-transparent.png';
import VideoPlayer from '../video-player/video-player';
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

const LandingPage = () => {
  const [currentDate, setCurrentDate] = useState("");
  const style = { color: "white", fontSize: "0.8rem" }

  useEffect(() => {
    // Get the current date in YYYY-MM-DD format
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Extracts YYYY-MM-DD
    setCurrentDate(formattedDate);
  }, []);

  return (
    <div className="landing-page raleway-400">
      <div className="video-container">
        <VideoPlayer /> 
        <header className="header">
          <div className="centered-content">
            <div className="logo">
            {/* 150x175 logo size */}
              <img src={LogoPNG} alt="Southport Old Links Logo" width='150' height='175' /> 
            </div>
            <h1 className="title">where golf feels like home</h1>
          </div>
        </header>
      </div>

      <div className="cta-container">
        <a href={`https://solgc.hub.clubv1.com/visitors/TeeSheet?date=${currentDate}`} className="cta-section cta-1">
            <h3>Fancy a round?</h3>
            <p>View our Tee sheet and book a Tee Time.</p>
            <div class="horizontal-line"></div>    
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/how-to-join" className="cta-section cta-2">
          <h3>Interested in joining?</h3>
          <p>View our Membership options.</p>
          <div class="horizontal-line"></div> 
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/course-flyovers-and-videos" className="cta-section cta-3">
          <h3>Come fly with me</h3>
          <p>Experience the course from above.</p>
          <div class="horizontal-line"></div> 
        </a>
      </div>

      <div className="extra-content">
        <h2>SOUTHPORT OLD LINKS</h2>
        <p className='subtitle-header'>where golf feels like home</p>
        <div class="horizontal-line"></div> 
        <div class="paragraph-container">     
          <p class="center-aligned">We are dedicated to providing a warm and inclusive environment for all golf enthusiasts.<br/>
We take pride in offering an affordable yet exceptional golfing experience on our
challenging course, consistently maintained for quality and your enjoyment.
        </p> 
        </div>    
      </div>

      <div className="cta-container">
        <a href="https://www.southportoldlinksgolfclub.co.uk/" className="cta-section color-section">
            <h3>Home</h3>
            <div class="horizontal-line"></div>    
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/functions" className="cta-section color-section">
          <h3>Functions</h3>
          <div class="horizontal-line"></div> 
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/societies" className="cta-section color-section">
          <h3>Visitors</h3>
          <div class="horizontal-line"></div> 
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/course-guide" className="cta-section color-section second-row">
            <h3>The course</h3>
            <div class="horizontal-line"></div>    
        </a>
        <a href="https://solgc.hub.clubv1.com/" className="cta-section color-section second-row">
          <h3>Members hub</h3>
          <div class="horizontal-line"></div> 
        </a>
        <a href="https://www.southportoldlinksgolfclub.co.uk/contact-info" className="cta-section color-section second-row">
          <h3>Contact us</h3>
          <div class="horizontal-line"></div> 
        </a>
      </div>

      <div className="extra-content">
        <span className="footer-social">
            <a href="https://www.facebook.com/oldlinksgolf" aria-label="Facebook Page"><FaFacebookF style={style} /></a>
            <a href="https://www.twitter.com/oldlinksgolf" aria-label="Twitter Page"><FaXTwitter style={style} /></a>
            <a href="https://www.instagram.com/oldlinksgolf" aria-label="instagram Page"><FaInstagram style={style} /></a>
        </span>
      </div> 
    </div>
  );
};

export default LandingPage;