import React from 'react';
import "../styles/variables.scss"
import loadable from '@loadable/component'



import LandingPage from '../components/landing-page/landing-page.js';

const IndexPage = () => {


const Seo = loadable(() => import("../components/seo/seo.js"))
  return (
    <main>
       <Seo title={"Home - Southport Old Links Golf Club"}></Seo>
      <LandingPage/>
    </main>
  );
};

export default IndexPage;