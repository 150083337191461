import React, { useState, useEffect } from "react";
import ArrowDown from '../../assets/arrow-down.png';

const VideoPlayer = ( ) => {
  const videoList = ["hole1","hole2","hole3","hole4","hole5","hole6","hole7","hole8","hole9"]//,"hole5.mp4","hole6.mp4","hole7.mp4","hole8.mp4","hole9.mp4"] // List of video files
  const [currentIndex, setCurrentIndex] = useState(-1); // Track the current video index
  const [isFading, setIsFading] = useState(false); // Track fade animation state


  const handleVideoEnd = () => {
    // Trigger fade-out animation
    setIsFading(true);
    //console.log("fading true")

    setTimeout(() => {
    // Update the video index after fade-out completes
    setCurrentIndex(getRandomIndex(currentIndex));//((prevIndex) => (prevIndex + 1) % videoList.length);
    setIsFading(false); // Reset fade state
    //console.log("fading false")
    }, 500); // Match fade-out animation duration
  };

  const scrollToNextSection = () => {
    const nextSection = document.querySelector(".cta-container");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getRandomIndex = (current) => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * videoList.length); // Pick a random index
    } while (newIndex === current); // Ensure it's not the same as the current index
    return newIndex;
  };

  const getVideoSource = (selectedClip) => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    const isLowBandwidth = connection && (connection.effectiveType === "2g" || connection.saveData);
    
    return isLowBandwidth ? selectedClip+"-720.mp4" : selectedClip + "-1080.mp4";
  };

  useEffect(() => {
    if(currentIndex == -1)
    {
      setCurrentIndex(getRandomIndex(currentIndex));
    }
    const videoElement = document.querySelector(".background-video");
    if (videoElement) {
      videoElement.load(); // Reload video when src changes
      videoElement.play(); // Ensure playback starts
    }
  }, [currentIndex]); // Run whenever the currentIndex changes

  return (
    (currentIndex > -1)?
    <div className={`${isFading ? "fade-out" : "fade-in"}`}>    
    <video
      autoPlay
      loop={false} // Disable default looping to handle manually
      muted
      preload="auto"
      playsInline
      className="background-video"
      onEnded={handleVideoEnd} // Triggered when video finishes
    >
      <source src={getVideoSource(videoList[currentIndex])} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    {/* Arrow Button */}
    <div className="arrow-container" onClick={scrollToNextSection}>
        <span className="arrow-icon">
        <img src={ArrowDown} alt="arrow down" width='37' height='54' /> 
        </span>
      </div>
    </div>
    : ""
  );
};

export default VideoPlayer;
